import { AxiosResponse, InternalAxiosRequestConfig } from './types'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { SUCCESS_CODE, TRANSFORM_REQUEST_DATA } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { objToFormData } from '@/utils'

const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
  if (
    config.method === 'post' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    config.data = qs.stringify(config.data)
  } else if (
    TRANSFORM_REQUEST_DATA &&
    config.method === 'post' &&
    config.headers['Content-Type'] === 'multipart/form-data'
  ) {
    config.data = objToFormData(config.data)
  }
  if (config.method === 'get' && config.params) {
    let url = config.url as string
    url += '?'
    const keys = Object.keys(config.params)

    for (const [index, key] of keys.entries()) {
      if (config.params[key] !== void 0 && config.params[key] !== null) {
        if (typeof config.params[key] == 'object') {
          // get请求参数为数组时，需要将数组转换为字符串
          const obj = {}
          obj[key] = config.params[key]
          const str = qs.stringify(obj, { arrayFormat: 'brackets' })
          url += str
        } else {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
    }

    url = url.substring(0, url.length - 1)
    config.params = {}
    config.url = url
  }
  // console.log('config======>',config)
  return config
}

const defaultResponseInterceptors = (response: AxiosResponse) => {
  // console.log('response======>',response)
  if (response?.config?.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  } else if (
    response.data.code === SUCCESS_CODE ||
    response.data.code === 1 ||
    response.data.code === 200 ||
    response.data.success === true
  ) {
    //成功状态码
    return response.data
  } else if (response.data.success === true) {
    //
    return response.data
  } else if (response.data.status == 500 && response.data.message == 'Token失效，请重新登录') {
    // }else if(response.data.status ==500){
    const userStore = useUserStoreWithOut()
    userStore.logout()
  } else if (response.data.code == 500 && response.data.message == '正在支付，请稍候！') {
  } else {
    ElMessage.error(response?.data?.message)
    if (response?.data?.code === 401) {
      const userStore = useUserStoreWithOut()
      userStore.logout()
    }
  }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }
