import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()
const baseImg = import.meta.env.VITE_BASE_PATH
export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal-center',
    name: 'Personal',
    meta: {
      title: t('router.personal'),
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: 'personal-center',
        component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
        name: 'PersonalCenter',
        meta: {
          title: t('router.personalCenter'),
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]
export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'home',
    meta: {},
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: '首页',
          // icon: 'ant-design:dashboard-filled',
          img: baseImg + 'imgs/icon/menu_1.png',
          imgH: baseImg + 'imgs/icon/menu_h_1.png',
          noCache: true,
          affix: true
        }
      }
    ]
  },
  {
    path: '/commodity',
    component: Layout,
    redirect: '/Commodity/productList',
    name: 'goods',
    meta: {
      title: '商品',
      img: baseImg + 'imgs/icon/menu_2.png',
      imgH: baseImg + 'imgs/icon/menu_h_2.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'product-list',
        component: () => import('@/views/Commodity/ProductList.vue'),
        name: 'goods-GoodsList',
        meta: {
          title: '商品列表',
          noCache: true
        }
      },
      {
        path: 'shop-class',
        component: () => import('@/views/Commodity/ShopClass.vue'),
        name: 'goods-ShopGoodsCategoryList',
        meta: {
          title: '店铺分类',
          noCache: true
        }
      },
      {
        path: 'brand-manage',
        component: () => import('@/views/Commodity/BrandManage.vue'),
        name: 'brand-BrandList',
        meta: {
          title: '品牌管理',
          noCache: true
        }
      },
      {
        path: 'evaluate-manage',
        component: () => import('@/views/Commodity/EvaluateManage.vue'),
        name: 'goods-GoodsEvaluateList',
        meta: {
          title: '评价管理',
          noCache: true
        }
      },
      {
        path: 'operate-log',
        component: () => import('@/views/Commodity/OperateLog.vue'),
        name: 'goods-GoodsLogList',
        meta: {
          title: '操作日志',
          noCache: true
        }
      },
      {
        path: 'recycle-bin',
        component: () => import('@/views/Commodity/RecycleBin.vue'),
        name: 'goods-RecycleBin',
        meta: {
          title: '回收站',
          noCache: true
        }
      },
      {
        path: 'add-product',
        component: () => import('@/views/Commodity/AddProduct.vue'),
        name: 'AddProduct',
        meta: {
          title: '添加商品',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/commodity/product-list'
        }
      }
    ]
  },
  {
    path: '/shop',
    component: Layout,
    redirect: '/Shop/ShopHome',
    name: 'shop',
    meta: {
      title: '店铺',
      img: baseImg + 'imgs/icon/menu_3.png',
      imgH: baseImg + 'imgs/icon/menu_h_3.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'shop-home',
        component: () => import('@/views/Shop/ShopHome.vue'),
        name: 'shop-ShopIndex',
        meta: {
          title: '店铺首页',
          noCache: true
        }
      },
      {
        path: 'store-information',
        component: () => import('@/views/Shop/StoreInformation.vue'),
        name: 'shop-ShopInfo',
        meta: {
          title: '店铺信息',
          noCache: true
        }
      },
      {
        path: 'account-management',
        component: () => import('@/views/Shop/AccountManagement.vue'),
        name: 'user-SubAccountList',
        meta: {
          title: '子账号管理',
          noCache: true
        }
      },
      {
        path: 'shop-qualification',
        component: () => import('@/views/Shop/ShopQualification.vue'),
        name: 'shopqualification-ShopQualificationList',
        meta: {
          title: '店铺资质',
          noCache: true
        }
      },
      {
        path: 'merchant-agreement',
        component: () => import('@/views/Shop/MerchantAgreement.vue'),
        name: 'shop-merchantAgreement',
        meta: {
          title: '商家协议',
          noCache: true
        }
      },
      {
        path: 'exit-store',
        component: () => import('@/views/Shop/ExitStore.vue'),
        name: 'shop-close-index',
        meta: {
          title: '退店',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/transaction',
    component: Layout,
    redirect: '/Transaction/OrderManage',
    name: 'layouts-RouteView',
    meta: {
      title: '交易',
      img: baseImg + 'imgs/icon/menu_4.png',
      imgH: baseImg + 'imgs/icon/menu_h_4.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'orderm-management',
        component: () => import('@/views/Transaction/OrderManage.vue'),
        name: 'order-OrderList',
        meta: {
          title: '订单管理',
          noCache: true
        }
      },
      {
        path: 'freight-template',
        component: () => import('@/views/Transaction/FreightTemplate.vue'),
        name: 'freight-template',
        meta: {
          title: '运费模板',
          noCache: true
        }
      },
      {
        path: 'order-logistics',
        component: () => import('@/views/Transaction/OrderLogistics.vue'),
        name: 'order-OrderShippingList',
        meta: {
          title: '订单物流',
          noCache: true
        }
      },
      {
        path: 'SF-delivery',
        component: () => import('@/views/Transaction/SFDelivery.vue'),
        name: 'order-SFExcelList',
        meta: {
          title: '顺丰发货',
          noCache: true
        }
      },
      {
        path: 'medical-sharing',
        component: () => import('@/views/Transaction/MedicalSharing.vue'),
        name: 'order-SFOrderList',
        meta: {
          title: '医药分享',
          noCache: true
        }
      },
      // {
      //   path: 'after-sales',
      //   component: () => import('@/views/Transaction/AfterSales.vue'),
      //   name: 'order-PrescribingStatisticsList',
      //   meta: {
      //     title: '退款/售后',
      //     noCache: true,
      //   }
      // },
      {
        path: 'prescription-list',
        component: () => import('@/views/Transaction/PrescriptionList.vue'),
        name: 'order-PrescribingStatisticsList',
        meta: {
          title: '处方单统计',
          noCache: true
        }
      },

      {
        path: 'order-details',
        component: () => import('@/views/Transaction/OrderDetail.vue'),
        name: 'OrderDetails',
        meta: {
          title: '订单详情',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/transaction/order-details'
        }
      },
      {
        path: 'freight-model',
        component: () => import('@/views/Transaction/FreightTemplateModel.vue'),
        name: 'FreightModel',
        meta: {
          title: '运费模板新增',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/transaction/freight-model'
        }
      }
    ]
  },
  {
    path: '/invoice',
    component: Layout,
    redirect: '/Invoice/InvoiceApplication',
    name: 'invoice',
    meta: {
      title: '发票',
      img: baseImg + 'imgs/icon/menu_5.png',
      imgH: baseImg + 'imgs/icon/menu_h_5.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'invoice-application',
        component: () => import('@/views/Invoice/InvoiceApplication.vue'),
        name: 'invoice-UserInvoiceList',
        meta: {
          title: '用户发票申请',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/reconciliation',
    component: Layout,
    redirect: '/Reconciliation/RecordList',
    name: 'reconciliation',
    meta: {
      title: '对账',
      img: baseImg + 'imgs/icon/menu_5.png',
      imgH: baseImg + 'imgs/icon/menu_h_5.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'reconciliation-recordList',
        component: () => import('@/views/Reconciliation/RecordList.vue'),
        name: 'reconciliation-record',
        meta: {
          title: '对账列表',
          noCache: true
        }
      },
      {
        path: 'invoice-application',
        component: () => import('@/views/Reconciliation/StatementMonthly.vue'),
        name: 'reconciliation-statementMonthly',
        meta: {
          title: '客服月账单',
          noCache: true
        }
      },
      {
        path: 'reconcilia-details',
        component: () => import('@/views/Reconciliation/Detail.vue'),
        name: 'ReconciliaDetails',
        meta: {
          title: '对账详情',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/reconciliation/detail'
        }
      },
      {
        path: 'monthlyBill-details',
        component: () => import('@/views/Reconciliation/monthlyBillDetails.vue'),
        name: 'MonthlyBillDetails',
        meta: {
          title: '对账详情',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/reconciliation/detail'
        }
      }
    ]
  },
  {
    path: '/video',
    component: Layout,
    redirect: '/Video/VideoList',
    name: 'video',
    meta: {
      title: '视频',
      img: baseImg + 'imgs/icon/menu_6.png',
      imgH: baseImg + 'imgs/icon/menu_h_6.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'video-list',
        component: () => import('@/views/Video/VideoList.vue'),
        name: 'video-video-list',
        meta: {
          title: '视频列表',
          noCache: true
        }
      },
      {
        path: 'comment-manage',
        component: () => import('@/views/Video/CommentManage.vue'),
        name: 'video-videoDiscuss-list',
        meta: {
          title: '评论管理',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/team',
    component: Layout,
    redirect: '/Team/TeamLeader',
    name: 'distribution',
    meta: {
      title: '分销',
      img: baseImg + 'imgs/icon/menu_7.png',
      imgH: baseImg + 'imgs/icon/menu_h_7.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'team-leader',
        component: () => import('@/views/Team/TeamLeader.vue'),
        name: 'Team-TeamLeader',
        meta: {
          title: '团长报表',
          noCache: true
        }
      },
      {
        path: 'manager-performanceTZ',
        component: () => import('@/views/Team/TZManagerPerformance.vue'),
        name: 'Team-ManagerPerformance',
        meta: {
          title: '经理业绩', //(团长)
          noCache: true
        }
      },
      {
        path: 'kefuManager-Performance',
        component: () => import('@/views/Team/KeFuManagerPerformance.vue'),
        name: 'Team-KeFuManagerPerformance',
        meta: {
          title: '经理业绩', //(客服)
          noCache: true
        }
      },
      {
        path: 'team-reconciliation',
        component: () => import('@/views/Team/Reconciliation.vue'),
        name: 'Team-Reconciliation',
        meta: {
          title: '代收对账',
          noCache: true
        }
      },
      {
        path: 'order-splitInfo',
        component: () => import('@/views/Team/OrderSplitInfoList.vue'),
        name: 'Team-OrderSplitInfoList',
        meta: {
          title: '分账订单',
          noCache: true
        }
      },
      {
        path: 'kefu-performance',
        component: () => import('@/views/Team/KeFuPerformance.vue'),
        name: 'Team-KeFuPerformance',
        meta: {
          title: '客服业绩',
          noCache: true
        }
      },
      {
        path: 'kefuPerformance-detail',
        component: () => import('@/views/Team/KeFuPerformanceDetail.vue'),
        name: 'KeFuPerformanceDetail',
        meta: {
          title: '客服业绩详情',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true
        }
      },
      {
        path: 'subordinates-leader',
        component: () => import('@/views/Team/SubordinatesLeader.vue'),
        name: 'SubordinatesLeader',
        meta: {
          title: '下级团长',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true
        }
      },
      {
        path: 'KeFusubordinates-leader',
        component: () => import('@/views/Team/KeFuSubordinatesLeader.vue'),
        name: 'KeFuSubordinatesLeader',
        meta: {
          title: '下级客服',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true
        }
      }
    ]
  },
  {
    path: '/totalData',
    component: Layout,
    redirect: '/TotalData/SalesDaily',
    name: 'data',
    meta: {
      title: '数据',
      img: baseImg + 'imgs/icon/menu_8.png',
      imgH: baseImg + 'imgs/icon/menu_h_8.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'sales-saily',
        component: () => import('@/views/TotalData/SalesDaily.vue'),
        name: 'browse-shopSalesReportInfo',
        meta: {
          title: '销售日报',
          noCache: true
        }
      },

      {
        path: 'product-data',
        component: () => import('@/views/TotalData/ProductData.vue'),
        name: 'browse-GoodsBrowse',
        meta: {
          title: '商品数据',
          noCache: true
        }
      },
      {
        path: 'order-info',
        component: () => import('@/views/TotalData/OrderInfo.vue'),
        name: 'browse-OrderListInfo',
        meta: {
          title: '订单详情',
          noCache: true
        }
      },
      {
        path: 'order-detail',
        component: () => import('@/views/TotalData/OrderDetail.vue'),
        name: 'OrderDetail',
        meta: {
          title: '详情',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true,
          activeMenu: '/totalData/order-detail'
        }
      },
      {
        path: 'marketing-data',
        component: () => import('@/views/TotalData/MarketingData.vue'),
        name: 'browse-ShopGoodsBrowse',
        meta: {
          title: '营销数据',
          noCache: true
        }
      },
      {
        path: 'shopBusiness-overview',
        component: () => import('@/views/TotalData/ShopBusinessOverview.vue'),
        name: 'finance-ShopBusinessOverview',
        meta: {
          title: '经营总览',
          noCache: true
        }
      },
      {
        path: 'data-settlement',
        component: () => import('@/views/TotalData/DataSettlement.vue'),
        name: 'finance-ShopBusinessDataSettlement',
        meta: {
          title: '数据概览',
          noCache: true
        }
      },
      {
        path: 'traffic-data',
        component: () => import('@/views/TotalData/TrafficData.vue'),
        name: 'browse-TrafficData',
        meta: {
          title: '流量数据',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/marketing',
    component: Layout,
    redirect: '/Marketing/MarketingActivities',
    name: 'activity',
    meta: {
      title: '营销',
      img: baseImg + 'imgs/icon/menu_9.png',
      imgH: baseImg + 'imgs/icon/menu_h_9.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'marketing-activities',
        component: () => import('@/views/Marketing/MarketingActivities.vue'),
        name: 'activity-ActivityList',
        meta: {
          title: '营销活动',
          noCache: true
        }
      },
      {
        path: 'flash-sale',
        component: () => import('@/views/Marketing/FlashSale.vue'),
        name: 'activity-SeckillList',
        meta: {
          title: '秒杀活动',
          noCache: true
        }
      },
      {
        path: 'coupon-management',
        component: () => import('@/views/Marketing/CouponManagement.vue'),
        name: 'coupon-CouponTypeList',
        meta: {
          title: '优惠券管理',
          noCache: true
        }
      },
      {
        path: 'full-discount',
        component: () => import('@/views/Marketing/FullDiscount.vue'),
        name: 'activity-ShopReductionList',
        meta: {
          title: '满减券管理',
          noCache: true
        }
      },
      {
        path: 'coupons-add',
        component: () => import('@/views/Marketing/CouponsAdd.vue'),
        name: 'CouponsAdd',
        meta: {
          title: '添加优惠券',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true
          // activeMenu: '/example/example-page'
        }
      },
      {
        path: 'select-product',
        component: () => import('@/views/Marketing/SelectProduct.vue'),
        name: 'SelectProduct',
        meta: {
          title: '选择商品',
          noTagsView: true,
          noCache: true,
          hidden: true,
          showMainRoute: true
          // activeMenu: '/example/example-page'
        }
      }
    ]
  },
  {
    path: '/after',
    component: Layout,
    redirect: '/AfterSales/AfterSales',
    // name: 'shopAccount',
    name: 'orderGoods',
    meta: {
      title: '售后',
      img: baseImg + 'imgs/icon/menu_91.png',
      imgH: baseImg + 'imgs/icon/menu_h_91.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'after-sales',
        component: () => import('@/views/AfterSales/AfterSales.vue'),
        name: 'order-orderGoods-list',
        meta: {
          title: '退款/售后',
          noCache: true
        }
      },
      {
        path: 'after-onLine',
        component: () => import('@/views/AfterSales/OnLineChargeback.vue'),
        name: 'platform-payOrderlist',
        meta: {
          title: '售后退单(线上)',
          noCache: true
        }
      },
      {
        path: 'after-offline',
        component: () => import('@/views/AfterSales/OfflineChargeback.vue'),
        name: 'platform-cashOrderlist',
        meta: {
          title: '售后退单(线下)',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/gifts',
    component: Layout,
    redirect: '/Gifts/GiftsList',
    // name: 'shopAccount',
    name: 'goodsGift-GoodsGiftList',
    meta: {
      title: '赠品',
      img: baseImg + 'imgs/icon/menu_92.png',
      imgH: baseImg + 'imgs/icon/menu_h_92.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'gifts-list',
        component: () => import('@/views/Gifts/GiftsList.vue'),
        name: 'goodsGift-GoodsGiftList',
        meta: {
          title: '赠品列表',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/account',
    component: Layout,
    redirect: '/Account/LoanAccount',
    // name: 'shopAccount',
    name: 'shopAccount',
    meta: {
      title: '账户',
      img: baseImg + 'imgs/icon/menu_90.png',
      imgH: baseImg + 'imgs/icon/menu_h_90.png',
      alwaysShow: true
    },
    children: [
      {
        path: 'loan-account',
        component: () => import('@/views/Account/LoanAccount.vue'),
        name: 'shop-AccountBillList',
        meta: {
          title: '贷款账户',
          noCache: true
        }
      },
      {
        path: 'store-balance',
        component: () => import('@/views/Account/StoreBalance.vue'),
        name: 'shopBalance-ShopBalance',
        meta: {
          title: '店铺余额',
          noCache: true
        }
      },
      {
        path: 'margin',
        component: () => import('@/views/Account/Margin.vue'),
        name: 'shopAccount-ShopSecurityDeposit',
        meta: {
          title: '保证金',
          noCache: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: false,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
