import 'vue/jsx'

import ElementPlus from 'element-plus'
// 引入windi css
import '@/plugins/unocss'
import 'element-plus/dist/index.css'

// 导入全局的svg图标
import '@/plugins/svgIcon'
import { ElMessage } from 'element-plus';
const app = createApp(App);
app.use(ElMessage);
// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from './router'

// 权限
import { setupPermission } from './directives'

import { createApp } from 'vue'

import App from './App.vue'

import './permission'

// 创建实例
const setupAll = async () => {
  const app = createApp(App)

  await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)

  setupPermission(app)
  
  app.use(ElementPlus)

  app.mount('#app')
}

setupAll()
